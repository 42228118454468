<template>
  <div
    :class="{ opened: isOpened }"
    class="seo-banner"
  >
    <div />
    <div>
      <AImage
        src="/assets/images/seo_banner.svg"
        alt="banner"
        :width="sizes.desktop.w"
        :height="sizes.desktop.h"
        class="seo-banner__img"
        from-public
      />
      <LazyMTextCollapse
        :text="textWithNoopenerLinks"
        :lines="4"
        class="text-editor"
        @collapse="isOpened = $event"
      />
    </div>
    <svg
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="border-radius">
          <feGaussianBlur
            in="SourceGraphic"
            stdDeviation="10"
            result="blur"
          />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="border-radius"
          />
          <feComposite
            in="SourceGraphic"
            in2="border-radius"
            operator="atop"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, defineComponent, hydrateOnVisible, ref } from 'vue'
import AImage from '@/components/atoms/Image/AImage.vue'

const LazyMTextCollapse = defineAsyncComponent({
  loader: () => /* @vite-ignore */ import('@/components/molecules/TextCollapse/MTextCollapse.vue'),
  hydrate: hydrateOnVisible()
})

defineComponent({ name: 'MSeoBanner' })

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})

const isOpened = ref(false)
const sizes = {
  desktop: {
    w: 168,
    h: 168
  }
}

const textWithNoopenerLinks = computed(() => props.text.replace(/target="_blank"/g, 'target="_blank" rel="noopener"'))
</script>

<style lang="postcss">
@import url("assets/styles/mixins/text-editor.pcss");

.seo-banner {
  position: relative;
  width: 100%;

  & > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: filter;

    &::before {
      content: "";
      display: block;
      height: 100%;
      background: var(--gradient-red-light);
      clip-path: polygon(0 0, 100% 0, 97% 100%, 0 100%);
    }
  }

  & > div:last-of-type {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--spacer-base);
  }

  & .seo-banner__img {
    grid-column: span 3;
    object-fit: contain;
    overflow: hidden;
    height: 168px;
    margin-top: var(--spacer-lg);

    & img {
      margin-top: 0;
    }
  }

  & > div:last-of-type > div:last-child {
    grid-column: span 8;
    margin-top: var(--spacer-3xl);
    margin-bottom: var(--spacer-3xl);
  }

  & .text-collapse {
    --text-collapse-mt: var(--spacer-2xs);
    --text-collapse-height: 7rem;

    color: var(--color-text-dark);

    & > div:first-child h2 {
      margin-bottom: 0;
    }

    & .action-btn {
      height: 1.5rem;
    }

    & > div:last-child:not(.action-btn) {
      margin-bottom: var(--spacer-2xl);
    }

    @mixin text-base;
  }

  &.opened {
    border-radius: var(--border-radius-lg);
    background: var(--gradient-red-light);

    & > div:first-child {
      display: none;
    }
  }
}

@media (--screen-lg) {
  .seo-banner:not(.opened) > div:first-child {
    filter: url("#border-radius");

    /* fix for safari < 15 */
    /* stylelint-disable -- This one is okay.*/
    @media not all and (min-resolution: 0.001dpcm) {
      @supports not (aspect-ratio: 1 / 1) {
        &::before {
          filter: url("#border-radius");
        }
      }
    }
  }
}

@media (--screen-xs) {
  .seo-banner {
    border-radius: var(--border-radius-xs);
    background: var(--gradient-red-light);

    & > div:first-child {
      display: none;
    }

    & > div:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: var(--spacer-2xs);
      padding: 0;
    }

    & > div:last-of-type > div:first-child {
      width: 100%;
    }

    & .seo-banner__img {
      min-width: 80px;
      height: auto;
      margin: 0 auto;

      & img {
        margin-top: var(--spacer-xs);
        width: 80px;
      }
    }

    & > div:last-of-type > div:last-child {
      margin: var(--spacer-base) var(--spacer-xs) var(--spacer-base) 0;
      width: calc(100% - 108px);
    }

    & .text-collapse > div {
      --text-collapse-mt: var(--spacer-4xs);

      &:last-child:not(.action-btn) {
        margin-bottom: var(--spacer-lg);
      }

      & .action-btn__inner.underline {
        line-height: calc(1.25rem - 1px);

        @mixin text-sm;
      }

      @mixin text-sm;
    }

    &.opened {
      height: auto;
    }
  }
}
</style>
